<template>
  <div>
    <v-card :elevation="0" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-0">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>

      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Solicitudes de usuarios
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="solicitudes"
          :search="search"
          :loading="tableLoading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}',
          }"
        >

          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="search"
                    label="Código de solicitud, institución, solicitante"
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <!-- <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col> -->

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.fechaCreacion | formatDate }}</td>
              <td>{{ item.codigoSolicitud }}</td>
              <td>{{ item.tipoSolicitud }}</td>
              <td>{{ item.entidad }}</td>
              <td>{{ item.unidadEjecutora }}</td>
              <td>{{ item.nombreSolicitante }}</td>
              <td>{{ item.correoSolicitante }}</td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1
                      ? 'cyan lighten-4'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 6
                      ? 'blue lighten-4'
                      : item.estadosId === 5
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'cyan darken-1'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 6
                      ? 'blue darken-1'
                      : item.estadosId === 5
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>

              <td>
                <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDetallesSolicitud(item.id)"
                >
                  <v-icon left>mdi-check-all</v-icon> Atender
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="
                    mostrarDialogRechazar(
                      item.id,
                      item.entidad,
                      item.fechaCreacion,
                      item.codigoSolicitud
                    )
                  "
                >
                  <v-icon left>mdi-window-close</v-icon> Rechazar solicitud
                </v-btn>

                <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="descargarArchivo(item.pathSolicitud)"
                >
                  <v-icon left>mdi-cloud-download</v-icon> Descargar solicitud
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogDetalleUsuarios"
      max-width="1024px"
      persistent
      transition="scroll-y-transition"
    >
      <v-card scrollable>
        <v-card-title>
          Detalle de la solicitud {{ detalleSolicitud.codigoSolicitud }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialogDetalleUsuarios = false;
              resetDetalleSolicitud();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-0"></v-divider>

        <v-card-text class="p-10 pt-1">
          <div v-if="skeletonDetalle">
            <v-row>
              <v-col cols="12" md="12">
                <v-skeleton-loader
                  v-bind="attrs"
                  min-height="600px"
                  type="article, article, article, actions"
                >
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </div>

          <div v-if="!skeletonDetalle">
            <v-row class="mt-1">
              <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Fecha de la solicitud</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.fechaCreacion | formatDate
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Tipo de solicitud</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.tipoSolicitud
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Institución</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.entidad
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Unidad Ejecutora</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.unidadEjecutora
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Nombre del solicitante</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.nombreSolicitante
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Correo del solicitante</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.correoSolicitante
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Nombre de la Máxima Autoridad</v-list-item-title
                    >
                    <v-list-item-subtitle class="text-subtitle-1">{{
                      detalleSolicitud.nombreMaximaAutoridad
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row class="mt-8">
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-subheader class="text-h6 black--text">
                  Usuarios solicitados
                </v-subheader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-2 pb-3">
                <v-data-table
                  class="elevation-1"
                  :headers="headersUsuariosResumen"
                  :items="usuariosSolicitud"
                  :loading="tableDetallesLoading"
                  hide-default-footer
                  no-data-text="No hay usuarios agregados para la solicitud"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}',
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <!-- <td>{{ item.municipio ? item.municipio + ", " :  ""}} {{item.departamento}}</td> -->
                      <td>{{ item.nombres }} {{ item.apellidos }}</td>
                      <td>{{ item.correo }}</td>
                      <td>{{ item.cargo }}</td>
                      <td>{{ item.proyecto }}</td>
                      <td>{{ item.estadosId === 3 ? item.usuario : `N/A` }}</td>
                      <td>
                        <v-chip
                          class="ma-2 font-weight-medium"
                          label
                          :color="
                            item.estadosId === 1
                              ? 'blue-grey lighten-4'
                              : item.estadosId === 2
                              ? 'deep-orange lighten-4'
                              : item.estadosId === 3
                              ? 'blue lighten-4'
                              : item.estadosId === 4
                              ? 'teal lighten-4'
                              : item.estadosId === 4
                              ? 'deep-orange lighten-4'
                              : 'pink lighten-4'
                          "
                          :text-color="
                            item.estadosId === 1
                              ? 'blue-grey lighten-1'
                              : item.estadosId === 2
                              ? 'deep-orange lighten-1'
                              : item.estadosId === 3
                              ? 'blue lighten-1'
                              : item.estadosId === 4
                              ? 'teal lighten-1'
                              : item.estadosId === 4
                              ? 'deep-orange lighten-1'
                              : 'pink darken-1'
                          "
                          small
                        >
                          {{
                            item.estadosId === 1
                              ? `Solicitado`
                              : item.estadosId === 3
                              ? `Vinculado`
                              : item.estado
                          }}
                        </v-chip>
                      </td>

                      <td>
                        <v-btn
                          v-if="item.estadosId === 1"
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          color="blue-grey lighten-5"
                          @click="
                            generarUsuario(
                              detalleSolicitud.id,
                              item.id,
                              item.nombres,
                              item.apellidos,
                              item.correo,
                              item.telefonos,
                              item.area,
                              item.cargo,
                              item.tipoProyecto,
                              item.proyectoId
                            )
                          "
                        >
                          <v-icon left>mdi-account-check</v-icon> Vincular
                          usuario
                        </v-btn>

                        <v-tooltip top v-if="item.observaciones">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                              small
                              depressed
                              color="blue-grey lighten-5"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left
                                >mdi-file-document-edit-outline</v-icon
                              >

                              Observaciones
                            </v-btn>
                          </template>
                          <span>{{ item.observaciones }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>

                  <!-- <template v-slot:[`item.entidad`]="{ item }">
                                    {{item.entidad}}, {{item.unidadEjecutora}}
                                </template> -->
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--begin:: dialog rechazo de solicitud -->
    <v-dialog
      v-model="dialogRechazar"
      width="600"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          Rechazar solicitud
        </v-card-title>

        <v-card-text>
          <v-row class="mt-4">
            <v-col sm="12">
              <p class="text-h6">
                ¿Está seguro de rechazar la solicitd? <br />
              </p>
              <p class="text-subtitle-1">
                <span>{{ datosSolicitudRechazo.codigoSolicitud }}</span> <br />
                <span>{{ datosSolicitudRechazo.fecha }}</span> <br />
                <span>{{ datosSolicitudRechazo.institucion }}</span>
              </p>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-alert outlined type="warning" dense>
                Esta acción es irreversible
              </v-alert>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col sm="12">
              <v-textarea
                autocomplete="off"
                class="required"
                dense
                filled
                v-model="datosSolicitudRechazo.motivoRechazo"
                label="Detalle el motivo del rechazo"
                :rules="[required('Motivo del rechazo')]"
                maxlength="2000"
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="dialogRechazar = false"
            :disabled="btnEstadoLoading"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="light-blue-502"
            class="white--text mb-2 float-right"
            type="submit"
            dark
            :loading="btnEstadoLoading"
            @click="rechazarSolicitud()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
import {
  OBTENER_SOLICITUDES_USUARIOS,
  OBTENER_SOLICITUD_USUARIOS,
  GENERAR_USUARIO_SOLICITUD,
  RECHAZAR_SOLICITUD_USUARIOS,
} from "@/core/services/store/solicitudesusuarios/solicitudusuario.module";
//import { OBTENER_SUBITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "SolicitudUsuariosInterno",
  components: {
    DivisorColor,
    SnackAlert,
    DialogLoader,
  },
  data() {
    return {
      tableLoading: false,
      tableDetallesLoading: false,
      solicitudes: [],
      detalleSolicitud: {},
      usuariosSolicitud: [],
      search: "",
      dialogDetalleUsuarios: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      datosUsuario: {},
      skeletonDetalle: false,
      dialogRechazar: false,
      datosSolicitudRechazo: {
        codigoSolicitud: "",
        fecha: "",
        institucion: "",
        motivoRechazo: "",
      },

      btnEstadoLoading: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },
      ...validations,
    };
  },

  methods: {
    //Obtener las solicitudes registradas
    async obtenerUsuarios() {
      this.tableLoading = true;
      this.solicitudes = [];
      await this.$store
        .dispatch(OBTENER_SOLICITUDES_USUARIOS, { estadoId: 0 })
        .then((res) => {
          if (res.status === 200) {
            this.solicitudes = res.data;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.solicitudes = [];
          this.tableLoading = false;
        });
    },

    //Obtener los detalles de solicitud específica
    async obtenerDetallesSolicitud(solicitudId) {
      this.skeletonDetalle = true;

      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo detalles de la solicitud....";
      this.detalleSolicitud = {};
      await this.$store
        .dispatch(OBTENER_SOLICITUD_USUARIOS, solicitudId)
        .then((res) => {
          if (res.status === 200) {
            this.detalleSolicitud = res.data;
            this.usuariosSolicitud = this.detalleSolicitud.usuarios;
            this.dialogDetalleUsuarios = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
          this.skeletonDetalle = false;
        })
        .catch(() => {
          this.detalleSolicitud = {};
          this.dialogLoaderVisible = false;
          this.skeletonDetalle = false;
        });
    },

    resetDetalleSolicitud() {
      this.detalleSolicitud = {};
    },

    async generarUsuario(
      solicitudId,
      detalleId,
      nombres,
      apellidos,
      correo,
      telefonos,
      area,
      cargo,
      tipoProyecto,
      proyectoId
    ) {
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Generando usuario, por favor espere....";
      this.datosUsuario.nombres = nombres;
      this.datosUsuario.apellidos = apellidos;
      this.datosUsuario.email = correo;
      this.datosUsuario.telefonos = telefonos;
      this.datosUsuario.area = area;
      this.datosUsuario.cargo = cargo;
      this.datosUsuario.tipoProyecto = tipoProyecto;
      this.datosUsuario.entidadId = this.detalleSolicitud.entidadId;
      this.datosUsuario.proyectoId = proyectoId;
      this.datosUsuario.detalleSolicitudId = detalleId;

      await this.$store
        .dispatch(GENERAR_USUARIO_SOLICITUD, { datos: this.datosUsuario })
        .then((res) => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            // this.nombreUsuario = this.datosUsuario.nombres;
            //this.dialogDetalleUsuarios = false;
            // this.usuariosSolicitud=[];
            this.datosUsuario = {};
            this.obtenerDetallesSolicitud(solicitudId);
            this.obtenerUsuarios();
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.btnRegistroLoading = false;
            //this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.dialogLoaderVisible = false;
        });

      //console.log(solicitudId + ` ` + detalleId)
      return;
    },

    //Mostrar el dialog para rechazar la solicitud
    mostrarDialogRechazar(id, entidad, fechaCreacion, codigo) {
      this.datosSolicitudRechazo = {
        id: id,
        codigoSolicitud: codigo,
        fecha: moment(fechaCreacion, "YYYY-MM-DD").format("DD-MM-YYYY"),
        institucion: entidad,
        motivoRechazo: "",
      };

      this.dialogRechazar = true;
    },

    async rechazarSolicitud() {
      if (this.datosSolicitudRechazo.motivoRechazo.length <= 0) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Debe describir el motivo del rechazo de la soliciutd`
        );
        return;
      }
      this.btnEstadoLoading = true;
      this.$store
        .dispatch(RECHAZAR_SOLICITUD_USUARIOS, this.datosSolicitudRechazo)
        .then((res) => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );

            this.obtenerUsuarios();
            //this.resetForm();
            this.datosSolicitudRechazo = {
              id: 0,
              codigoSolicitud: "",
              fecha: "",
              institucion: "",
              motivoRechazo: "",
            };
            this.dialogRechazar = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEstadoLoading = false;
        })
        .catch((error) => {
          this.btnEstadoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //!Descargar el desde server
    descargarArchivo(path) {
      DownloadFile.download(path);
    }
  },
  created() {
    this.obtenerUsuarios();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/" },
      { title: "Solicitudes" },
      { title: "Usuarios Proyecto" },
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "fechaCreacion",
        },
        {
          text: "Código",
          align: "left",
          sortable: true,
          value: "codigoSolicitud"
        },
        {
          text: "Tipo de Solicitud",
          align: "left",
          sortable: true,
          value: "tipoSolicitud"
        },
        {
          text: "Institución",
          align: "left",
          sortable: false,
          value: "institucion"
        },
        { text: "Unidad Ejecutora", value: "unidadEjecutora" },
        { text: "Solicitante", value: "nombreSolicitante" },
        { text: "Correo Solicitante", value: "correoSolicitante" },
        { text: "Estado", value: "estado" },
        { text: "Acciones", value: "iron" }
      ];
    },

    headersUsuariosResumen() {
      return [
        { text: "Nombre", value: "nombres" },
        { text: "Correo", value: "correo" },
        { text: "Área y Cargo", value: "area" },
        /*  { text: "Institución, Unidad Ejecutora", value: "entidad"},
         */ { text: "Proyecto", value: "proyecto" },
        { text: "Usuario", value: "usuario" },
        { text: "Estado", value: "estadosId" },

        { text: "", value: "accion" }
      ];
    },
  },
};
</script>
