import Vue from "vue";

const DownloadFile = {
  download(path) {
    let newPath = path.replace(/\\/g, "/");
    //console.log(newPath);
    /*var newWin = window.open(
      "https://if.sicoopera.gt:8443" + newPath,
      "_blank"
    );*/
    //Server Segeplan
    //var newWin = window.open('http://ifsicoopera.mall502.com' + newPath, "_blank"); //Server NT
    var newWin = window.open(Vue.prototype.$apiUrl + newPath, "_blank"); // Local

    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      //POPUP BLOCKED
      return false;
    }
    return true;
  },

  downloadWS(path) {
    let newPath = path.replace(/\\/g, "/");
    window.open("https://ws.sicoopera.gt" + newPath, "_blank"); // Server Segeplan
    //window.open('http://wssigeaci.mall502.com' + newPath, "_blank"); //Server NT
    //window.open('https://localhost:7125' + newPath, "_blank");
  },

  downloadSigeaci(path) {
    let newPath = path.replace(/\\/g, "/");
    window.open("https://appsigeaci.segeplan.gob.gt/" + newPath, "_blank"); // Server sigeaci
  },

  downloadExternal(path) {
    let newPath = path.replace(/\\/g, "/");
    window.open(newPath, "_blank"); // Server sigeaci
  }
};
export default DownloadFile;
